import { AbilityBuilder, PureAbility } from '@casl/ability'
import { AdminRolesT, AppPermissionsT, AppResourceT } from '../types/permission-types'

export type Subjects = string
export type Actions = 'manage' | 'create' | 'read' | 'update' | 'delete'

export type AppAbilityT = PureAbility<[Actions, Subjects]>
export const AppAbility = PureAbility

export type ACLObj = {
  action: Actions;
  subject: AppResourceT | "all"
}

/**
 * Define permissions for a role.
 * This defines what pages/ui elements are accesssible.
 * Backend defined Permissions for roles.
 * Default Permissions for role + User Specific permissions(overrides the default)
 */
const defineRulesFor = (role: AdminRolesT, subject: string, permissions: AppPermissionsT) => {
  const { can, build } = new AbilityBuilder<AppAbilityT>(AppAbility);

  Object.keys(permissions).forEach((resource) => {
    let _resource = resource as AppResourceT;
    const resourcePermissions = permissions[_resource];

    if (role === "superadmin") can("manage", [subject, _resource, "all"]);
    if (resourcePermissions.create) can('create', [subject, _resource]);
    if (resourcePermissions.view) can("read", [subject, _resource]);
    if (resourcePermissions.edit) can("update", [subject, _resource]);
    if (resourcePermissions.delete) can("delete", [subject, _resource]);
  });

  // if (role === "superadmin") {
  //   can("manage", [subject, "all"]);
  // } else if (role === "admin") {
  //   can(["create", "read", "update", "email"], subject);
  // } else if (role === "editor") {
  //   can(["read", "update"], subject);
  // } else if (role === "user") {
  //   can(["read"], subject);
  // } else {
  //   can("read", subject);
  // }

  return build;
};

export const buildAbilityFor = (role: AdminRolesT, subject: string, permissions: AppPermissionsT): AppAbilityT => {
  const ability = defineRulesFor(role, subject, permissions)

  return ability({
    // See: https://casl.js.org/v6/en/guide/subject-type-detection#custom-subject-type-detection
    // @ts-ignore
    detectSubjectType: object => object!.type // mainly is string
  })
}

export const defaultACLObj: ACLObj = {
  action: 'read',
  subject: 'all'
}
