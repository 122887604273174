// ** Type import
import { VerticalNavItemsType } from "../comps/@core/layouts/types";

// ** Icon imports
import {
  AccountOutline,
  FileDocumentOutline,
  TagOutline,
  CogOutline,
  Email,
} from "mdi-material-ui";

import { CustomerListScreen } from "../screens/customer/list";
import { CustomerViewScreen } from "../screens/customer/view";
import CustomerRolesScreen from "../screens/customer/roles/view";

import { OrderListScreen } from "../screens/order/list";
import { OrderPreviewScreen } from "../screens/order/preview";
import { OrderPrintScreen } from "../screens/order/print";
import AddOrderScreen from "../screens/order/add";

import { LoginScreen } from "../screens/login";
// import { RegisterScreen } from "../screens/register";
import { ForgotPasswordScreen } from "../screens/forgotPassword";

import { ProductListScreen } from "../screens/products/list";
import { NewProductScreen } from "../screens/products/addProduct";
import ViewProductScreen from "../screens/products/viewProduct";

import { HomeScreen } from "../screens/home";
import Error404 from "../screens/404";
import MailBoxScreen from "../screens/mailbox";

// import CrmDashboardScreen from "../screens/dashboards/crm";
// import AnalyticsDashboardScreen from "../screens/dashboards/analytics";
// import EcommerceDashboardScreen from "../screens/dashboards/ecommerce";

import { AccountSettingsScreen } from "../screens/settings/admin/accountSettings/accountSettings";
import { AdminListScreen } from "../screens/settings/admin/list";
import { AdminEditScreen } from "../screens/settings/admin/edit";
import ViewAdminScreen from "../screens/settings/admin/view";
import AdminRolesScreen from "../screens/settings/roles/view";

import MailAccounts from "../screens/settings/mail";
import TranslationsSettingsScreen from "../screens/settings/translations";

import CompanyListScreen from "../screens/settings/companies";
import AddCompanyScreen from "../screens/settings/companies/addCompany";
import PreviewCompanyScreen from "../screens/settings/companies/previewCompany";

import { OrderStatusScreen } from "../screens/settings/orderStatus";
import ViewOrderStatusScreen from "../screens/settings/orderStatus/view";
import ProductCategoriesScreen from "../screens/products/categories";
import ImportProductsScreen from "../screens/products/import";
import useDocsNav from "./useDocsNav";

/**
 * @description: navigation for side navigation
 * @typedef: for info about type definitions go to VerticalNavItemsType
 * **/

const Navigation = (): VerticalNavItemsType => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const baseRoutes = [
    // {
    //   title: "Nástroje",
    //   icon: HomeOutline,
    //   children: [
    //     {
    //       title: "CRM",
    //       path: "/",
    //       element: CrmDashboardScreen,
    //     },
    //     {
    //       title: "Štatistiky",
    //       path: "/dashboards/analytics",
    //       element: AnalyticsDashboardScreen,
    //     },
    //     {
    //       title: "Online obchod",
    //       path: "/dashboards/ecommerce",
    //       element: EcommerceDashboardScreen,
    //     },
    //   ],
    // },

    {
      title: "CRM",
      path: "/",
      element: HomeScreen,
      hidden: true,
    },

    {
      title: "",
      path: "*",
      element: Error404,
      hidden: true,
      useLayout: false,
    },

    {
      sectionTitle: "App",
    },

    {
      title: "Customers",
      icon: AccountOutline,
      children: [
        {
          title: "List",
          path: "/customer/list",
          element: CustomerListScreen,
        },
        {
          title: "Roles",
          path: "/customer/roles",
          element: CustomerRolesScreen,
          acl: {
            action: "manage",
            subject: "customers",
          },
          hidden: true, // WIP
        },
        {
          title: "View customer",
          path: "/customer/view/:id",
          element: CustomerViewScreen,
          hidden: true,
        },
      ],
    },

    {
      title: "Products",
      icon: TagOutline,
      path: "/products",
      children: [
        {
          title: "List",
          path: "/products",
          element: ProductListScreen,
        },
        {
          title: "Categories",
          path: "/products/categories",
          element: ProductCategoriesScreen,
        },
        {
          title: "Add product",
          path: "/products/new",
          element: NewProductScreen,
          acl: {
            action: "create",
            subject: "products",
          },
        },
        {
          title: "Import",
          path: "/products/import",
          element: ImportProductsScreen,
          acl: {
            action: "create",
            subject: "products",
          },
        },
        {
          title: "View product",
          path: "/products/preview/:id",
          element: ViewProductScreen,
          hidden: true,
        },
      ],
    },
    {
      title: "Orders",
      icon: FileDocumentOutline,
      children: [
        {
          title: "List",
          path: "/order/list",
          element: OrderListScreen,
        },
        {
          title: "Create order",
          path: "/order/add",
          element: AddOrderScreen,
          acl: {
            action: "create",
            subject: "orders",
          },
        },
        {
          title: "View order",
          path: "/order/preview/:id",
          element: OrderPreviewScreen,
          hidden: true,
        },
        {
          title: "Print",
          path: "/order/print/:id",
          element: OrderPrintScreen,
          useLayout: false,
          hidden: true,
          acl: {
            action: "create",
            subject: "orders",
          },
        },
      ],
    },
    {
      title: "Email",
      path: "/mailbox",
      icon: Email,
      element: MailBoxScreen,
      acl: {
        action: "read",
        subject: "email",
      },
    },

    {
      sectionTitle: "General",
      acl: { action: "manage", subject: "settings" },
    },

    {
      title: "Settings",
      icon: CogOutline,
      // acl: { action: "manage", subject: "settings" },
      children: [
        {
          title: "Order",
          children: [
            {
              title: "Statuses",
              path: "/settings/orderStatus/list",
              element: OrderStatusScreen,
              acl: { action: "manage", subject: "order-status" },
            },
            {
              title: "View Statuses",
              path: "/settings/orderStatus/preview/:id",
              element: ViewOrderStatusScreen,
              acl: { action: "manage", subject: "order-status" },
              hidden: true,
            },
          ],
        },
        {
          title: "Translations",
          path: "/translations",
          element: TranslationsSettingsScreen,
          acl: { action: "manage", subject: "translations" },
        },
        {
          title: "Email",
          children: [
            {
              title: "Accounts",
              path: "/settings/mail",
              element: MailAccounts,
              acl: { action: "manage", subject: "email-setup" },
            },
          ],
        },
        {
          title: "Companies",
          children: [
            {
              title: "List",
              path: "/company/list",
              element: CompanyListScreen,
              acl: { action: "manage", subject: "companies" },
            },
            {
              title: "Add company",
              path: "/company/new",
              element: AddCompanyScreen,
              acl: { action: "manage", subject: "companies" },
            },
            {
              title: "Preview Company",
              path: "/company/preview/:id",
              element: PreviewCompanyScreen,
              acl: { action: "manage", subject: "companies" },

              hidden: true,
            },
          ],
        },
        {
          title: "Users",
          children: [
            {
              title: "List",
              path: "/admin/list",
              element: AdminListScreen,
              acl: { action: "manage", subject: "users" },
            },
            {
              title: "Roles",
              path: "/admin/roles",
              element: AdminRolesScreen,
              acl: { action: "manage", subject: "users" },
              hidden: true,
            },
            {
              title: "Edit user",
              path: "/admin/edit/:id",
              element: AdminEditScreen,
              acl: { action: "manage", subject: "users" },

              hidden: true,
            },
            {
              title: "Edit user",
              path: "/admin/view/:id",
              element: ViewAdminScreen,
              acl: { action: "manage", subject: "users" },

              hidden: true,
            },
            {
              title: "Account settings",
              path: "/admin/account-settings/:id",
              element: AccountSettingsScreen,
              hidden: true,
            },
          ],
        },
      ],
    },

    // ==============AUTH=========================
    {
      title: "Login",
      path: "/login",
      element: LoginScreen,
      useLayout: false,
      guestGuard: true,
      hidden: true,
    },
    {
      title: "Forgotten password",
      path: "/forgot-password",
      element: ForgotPasswordScreen,
      useLayout: false,
      guestGuard: true,
      hidden: true,
    },
  ] as VerticalNavItemsType;

  const docRoutes = useDocsNav();

  return [
    ...baseRoutes,
    ...docRoutes
  ]
};

export default Navigation;
