// ** React Imports
import { useEffect, useMemo, useState } from "react";

// ** MUI Imports
import {
  Card,
  Grid,
  Button,
  CardHeader,
  CardContent,
  Divider,
} from "@mui/material";

// ** Third Party Imports
import {
  Control,
  FieldErrors,
  FormProvider,
  useForm,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

// ** Types
import {
  PorezProductFormInputsT,
  ProductFormInputs,
  ProductFormT,
} from "../../../types/productTypes";
import { AppDispatch, RootState } from "../../../store";

import { useAlertContext } from "../../../hooks/useAlertContext";

import { createProduct, updateProduct } from "../../../store/apps/product";
import { getProduct } from "../../../store/apps/product";
import useCompanies from "../../../hooks/useCompanies";
import { useAuth } from "../../../hooks/useAuth";

import DimensionsFields from "./DimensionsFields";
import ProductInfoFields from "./ProductInfoFields";
import StatusField from "./StatusField";
import ProductPricingFields from "./ProductPricingFields";
import ImageUploaderFields from "./ImageUploaderFields";
import { SectionDivider } from "./SectionDivider";
import { useSettings } from "../../../hooks/useSettings";
import PorezProductFields from "./porez-product-fields";
import prepareFormUpdatedValues from "./utils/prepare-form-updated-values";
import { getProductCategory } from "../../../store/apps/product-categories";
import { CategoryMarginAndDiscountT } from "../../../types/product-category-type";
import { useNavigate } from "react-router-dom";
import { productStatuses } from "../../../utils/products/data";

const basePriceAdjuster = {
  value: 0,
  prefix: "%",
};

const baseInitFormValues = {
  name: "",
  category: null,
  company: "",
  status: "draft",
  price: 0,

  onSale: false,
  saleEnds: new Date(),

  saleDiscount: basePriceAdjuster,
  wholesaleDiscount: basePriceAdjuster,
  customerDiscount: basePriceAdjuster,

  retailMargin: basePriceAdjuster,
  wholesaleCustomerMargin: basePriceAdjuster,
  retailCustomerMargin: basePriceAdjuster,
};

const variobelInitFormValues = {
  description: "",
  images: [],

  minWidth: 1000,
  maxWidth: 1000,
  minHeight: 1000,
  maxHeight: 1000,

  width: 0,
  height: 0,
};

const porezInitFormValues = {
  code: "",
  surface: "",
  image: "",
  thickness: [],
  condition: "old",
};

export const pricingPrefixList = ["%", "€"];

interface Props {
  type?: "create" | "edit";
  id?: string;
  handleClose?: () => void;
}

const ProductForm = (props: Props) => {
  const { id, handleClose } = props;
  const type = props.type ?? "create";

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const categories = useSelector(
    (state: RootState) => state.productCategories.data
  );
  const { t } = useTranslation();
  const { showSuccessAlert, showErrorAlert } = useAlertContext();
  const companies = useCompanies();
  const { user } = useAuth();
  const { settings } = useSettings();
  const { configuredCompany } = settings;

  const isVariobelOnlyField = configuredCompany === "variobel";

  const defaultValues = useMemo(() => {
    if (isVariobelOnlyField) {
      return Object.assign(
        baseInitFormValues,
        variobelInitFormValues
      ) as ProductFormInputs;
    }

    return Object.assign(
      baseInitFormValues,
      porezInitFormValues
    ) as PorezProductFormInputsT;
  }, [isVariobelOnlyField]);

  // ** Hooks
  const formMethods = useForm<ProductFormT>({ defaultValues });
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = formMethods;

  const watchedData = watch();
  const watchCompany = watch("company");
  const company = companies.find((c) => c._id === watchCompany);

  const [tax, setTax] = useState(0);

  // Used in EDIT mode to "filter" values passed as data when submitting form
  // It would contain only values that changed from their initial "state"
  const [initialFormValuesForEditFilter, setInitialFormValuesForEditFilter] =
    useState<ProductFormT | null>(null);

  const [categoryMarginAndDiscount, setCategoryMarginAndDiscount] =
    useState<CategoryMarginAndDiscountT>();

  // Fetch data in Edit mode
  // Sets intial values for:
  //    form values
  //    edit form values in setInitialFormValuesForEditFilter:
  useEffect(() => {
    if (type === "edit" && id) {
      (async () =>
        dispatch(getProduct(id)).then(async (res) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { _id, __v, ...data } = res.payload.product;
          if ("saleEnds" in data) {
            data.saleEnds = data.saleEnds ? new Date(data.saleEnds) : null;
          }

          const categoryId = data.category;

          if (categoryId) {
            const categoryRes = await dispatch(getProductCategory(categoryId));
            const { margin, discount } = categoryRes.payload.productCategory;
            setCategoryMarginAndDiscount({ margin, discount });
          }

          reset(data);
          setInitialFormValuesForEditFilter(data);
        }))();
    }
  }, [dispatch, id, reset, type]);

  // get company tax
  useEffect(() => {
    if (company) {
      setTax(company.taxRate);
    }
  }, [company]);

  const handleProductSubmit = () => {
    if (type === "create") {
      return dispatch(createProduct(watchedData))
        .unwrap()
        .then(() => {
          showSuccessAlert(t("Product created"));
          navigate("/products");
        })
        .catch(() => {
          showErrorAlert(t("Failed to create Product"));
        });
    }

    // type === "edit"
    const updatedValues = prepareFormUpdatedValues(
      watchedData,
      initialFormValuesForEditFilter,
      user,
      isVariobelOnlyField
    );

    return dispatch(updateProduct({ id: id as string, data: updatedValues }))
      .unwrap()
      .then(() => {
        showSuccessAlert(t("Product updated"));
      })
      .catch(() => {
        showErrorAlert(t("Failed to update product"));
      });
  };

  const onSubmit = () => {
    handleProductSubmit();
    handleClose && handleClose();
  };

  return (
    <Card
      sx={
        type === "edit"
          ? {
              boxShadow: "none",
            }
          : {}
      }
    >
      <CardHeader
        title={type !== "create" ? t("Update product") : t("Add product")}
        titleTypographyProps={{ variant: "h6" }}
        subheader={t("Begin with name, price and picture.")}
        sx={{ mb: 1 }}
      />

      <CardContent>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={5}>
              <StatusField
                {...{ control, t, errors, statuses: [...productStatuses] }}
              />

              <ProductInfoFields
                {...{
                  control,
                  t,
                  errors,
                  companies,
                  categories,
                  isVariobelOnlyField,
                }}
              />

              {isVariobelOnlyField && (
                <>
                  <SectionDivider caption={t("Dimensions (W x H)")} />
                  <DimensionsFields
                    {...{
                      control: control as unknown as Control<
                        ProductFormInputs,
                        any
                      >,
                      t,
                      errors,
                    }}
                  />
                </>
              )}

              <SectionDivider caption={t("Product Pricing")} />

              <ProductPricingFields
                {...{
                  control,
                  t,
                  errors,
                  setValue,
                  tax,
                  isVariobelOnlyField,
                  categoryMarginAndDiscount,
                }}
              />

              {!isVariobelOnlyField && (
                <PorezProductFields
                  {...{ control: control, t, errors, setValue }}
                />
              )}

              <Grid item xs={12}>
                <Divider />
              </Grid>

              {isVariobelOnlyField && (
                <ImageUploaderFields
                  {...{
                    control: control as unknown as Control<
                      ProductFormInputs,
                      any
                    >,
                    errors: errors as FieldErrors<ProductFormInputs>,
                    setValue,
                    t,
                  }}
                />
              )}

              <Grid item xs={12}>
                <Button
                  size="large"
                  type="submit"
                  variant="contained"
                  sx={{ textTransform: "capitalize" }}
                >
                  {type !== "create" ? t("Save") : t("Add product")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </CardContent>
    </Card>
  );
};

export default ProductForm;
