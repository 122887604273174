import React, { ReactNode, useEffect, useState } from "react";
import { ACLObj, AppAbilityT, buildAbilityFor } from "../../configs/acl";
import NotAuthorized from "../401";
import { AbilityContext } from "../layouts/components/acl/Can";
import { useAuth } from "../../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store";
import { fetchPerms, selectPermissions } from "../../store/apps/permissions";

interface Props {
  children: ReactNode;
  guestGuard: boolean;
  aclAbilities: ACLObj;
}

const AclGuard = (props: Props) => {
  const { aclAbilities, children, guestGuard } = props;

  const [ability, setAbility] = useState<AppAbilityT | undefined>(undefined);

  const auth = useAuth();
  const dispatch = useDispatch<AppDispatch>();
  const permissions = useSelector(selectPermissions);

  // const router = useRouter()

  useEffect(() => {
    dispatch(fetchPerms());
  }, [dispatch]);

  // Build Access control for user
  if (
      auth.user // logged in
      && auth.user.role // has role
      && !ability // no ability
      && Object.keys(permissions).length // existing permissions
    ) {
    setAbility(
      buildAbilityFor(auth.user.role, aclAbilities.subject, permissions)
    );
  }

  // If guestGuard is true and user is not logged in or its an error page, render the page without checking access
  if (
    guestGuard /* || router.route === '/404' || router.route === '/500' || router.route === '/' */
  ) {
    return <>{children}</>;
  }

  // Check the access of current user and render pages
  if (ability && ability.can(aclAbilities.action, aclAbilities.subject)) {
    return (
      <AbilityContext.Provider value={ability}>
        {children}
      </AbilityContext.Provider>
    );
  }

  // Render Not Authorized component if the current user has limited access
  return <NotAuthorized />;
};

export default AclGuard;
