import { Dispatch } from "redux";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { DocLinkT, DocT } from "../../../types/doc-types";

type InitialDocsStoreStateT = {
  links: {
    userManualLinks: DocLinkT[];
    technicalDocsLinks: DocLinkT[]
  },
  docs: DocT[]
}

interface Redux {
  getState: any;
  dispatch: Dispatch<any>;
}

const initialDocsStoreState: InitialDocsStoreStateT = {
  links: {
    userManualLinks: [],
    technicalDocsLinks: []
  },
  docs: []
};

export const fetchAllDocs = createAsyncThunk(
  "docs/get-all",
  async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_HOST}/docs/`,
      { withCredentials: true }
    );

    const resp = response.data as DocT[];
    return resp;
  }
);

export const fetchDocsNavLink = createAsyncThunk("docs/build-nav", async() => {
  const response = await axios.get(
    `${process.env.REACT_APP_SERVER_HOST}/docs/build-nav`,
    { withCredentials: true }
  );

  return response.data;
});

export const createDoc = createAsyncThunk(
  "docs/create",
  async (data: any, { getState, dispatch }: Redux) => {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_HOST}/docs`,
      data,
      { withCredentials: true }
    );

    await dispatch(fetchDocsNavLink());
    await dispatch(fetchAllDocs());

    return response.data as DocT;
  }
);

export const updateDoc = createAsyncThunk(
  "docs/update",
  async (
    { id, data }: { id: string; data: any },
    { getState, dispatch }: Redux
  ) => {
    const response = await axios.patch(
      `${process.env.REACT_APP_SERVER_HOST}/docs/${id}`,
      data,
      { withCredentials: true }
    );

    await dispatch(fetchDocsNavLink());
    await dispatch(fetchAllDocs());

    return response.data as DocT;
  }
);

export const fetchDoc = createAsyncThunk(
  "docs/get-one",
  async (
    id: string,
    { getState, dispatch }: Redux) => {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_HOST}/docs/${id}`,
      { withCredentials: true }
    );

    const resp = response.data as DocT;
    return resp;
  }
);

export const appDocsSlice = createSlice({
  name: "app-docs-store",
  initialState: initialDocsStoreState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDocsNavLink.fulfilled, (state, action) => {
      state.links = action.payload;
    });

    builder.addCase(fetchAllDocs.fulfilled, (state, action) => {
      state.docs = action.payload;
    });
  },
});

export default appDocsSlice.reducer;
