import { Box, Card, CardHeader, CardContent, Typography, IconButton } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { AppDispatch } from "../../store";
import { fetchDoc } from "../../store/apps/docs";
import { DocT, DocTypeT } from "../../types/doc-types";
import { Delete, FileEditOutline } from "mdi-material-ui";
import DocsForm from "./docs-form";
import { AbilityContext } from "../layouts/components/acl/Can";
import { useAbility } from "@casl/react";

interface Props {
  docType: DocTypeT
}

const DocsViewer = (props: Props) => {
  const { docType } = props;

  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const ability = useAbility(AbilityContext);

  const canManage = ability.can('manage', 'docs');

  const id = useMemo(() => {
    const paths = location.pathname.split("/");
    const id = paths.at(-1);

    return id;
  }, [location.pathname]);

  const [doc, setDoc] = useState<DocT | null>(null);
  const [editMode, setEditMode] = useState(false);

  const toggleEditMode = () => setEditMode((prev) => !prev);

  const handleFetchCallback = () => {
    if (!id) return;

    dispatch(fetchDoc(id))
      .unwrap()
      .then((res) => setDoc(res));
  }

  const handleFetch = useCallback(handleFetchCallback, [dispatch, id]);

  const handleEditSuccess = () => {
    setEditMode(false);
    handleFetch();
  }

  useEffect(() => {
    handleFetch();
  }, [handleFetch]);

  if (!doc) return null;

  if (editMode) {
    return (
      <DocsForm
        type="edit"
        docType={docType}
        editID={id}
        editCallback={handleEditSuccess}
      />
    );
  }

  return (
    <Card>
      <CardHeader
        component={() => (
          <Box
            sx={{
              p: 5,
              pb: 0,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                textTransform: "capitalize",
                width: "50%",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {doc.pageNavTitle}
            </Typography>

            {canManage && (
              <Box>
                <IconButton onClick={toggleEditMode}>
                  <FileEditOutline />
                </IconButton>

                <IconButton>
                  <Delete />
                </IconButton>
              </Box>
            )}
          </Box>
        )}
      />
      <CardContent>
        <Box
          dangerouslySetInnerHTML={{ __html: doc.content }}
          sx={{
            background: (theme) => theme.palette.background.default,
            minHeight: 400,
            padding: 4,
            borderRadius: 1,
          }}
        />
      </CardContent>
    </Card>
  );
};

export default DocsViewer;
