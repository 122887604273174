import yup from "../../helpers/customYup";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Box,
  Button,
  CardActions,
  TextField,
  FormHelperText,
  LinearProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AppDispatch } from "../../store";
import { useDispatch } from "react-redux";
import { createDoc, fetchDoc, updateDoc } from "../../store/apps/docs";
import { useAlertContext } from "../../hooks/useAlertContext";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { DocTypeT } from "../../types/doc-types";
import DocsEditor from "./docs-editor";

type Inputs = {
  title: string;
  content: string;
};

type PayloadT = {
  pageNavTitle: string;
  type: DocTypeT;
  content: string;
};

type EditPayloadT = { id: string; data: PayloadT };

interface Props {
  type: "add" | "edit";
  docType: DocTypeT;
  editID?: string;
  editCallback?: () => void;
}

const InputsValidation = yup.object({
  title: yup.string().required().min(1),
  content: yup.string().required(),
});

const DocsForm = (props: Props) => {
  const { type, docType, editID, editCallback } = props;
  const docsEditorRef = useRef<HTMLDivElement | null>(null);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { showSuccessAlert, showErrorAlert } = useAlertContext();
  const formMethods = useForm<Inputs>({
    defaultValues: {
      title: "",
      content: "",
    },
    mode: "onBlur",
    resolver: yupResolver(InputsValidation),
  });
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = formMethods;

  const handleSetEditorContent = (data: string) => {
    if (docsEditorRef.current) {
      // Property 'setEditorContent' does not exist on type 'HTMLDivElement'
      // @ts-expect-error
      docsEditorRef.current.setEditorContent(data);
    }
  };

  const handleClearEditorContent = () => {
    if (docsEditorRef.current) {
      // Property 'clearEditorContent' does not exist on type 'HTMLDivElement'
      // @ts-expect-error
      docsEditorRef.current.clearEditorContent();
    }
  };

  useEffect(() => {
    if (editID && type === "edit") {
      dispatch(fetchDoc(editID))
        .unwrap()
        .then((res) => {
          setValue("title", res.pageNavTitle);
          setValue("content", res.content);
          handleSetEditorContent(res.content);
        });
    }
  }, [dispatch, editID, setValue, type]);

  const handleFormReset = () => {
    reset();
    handleClearEditorContent();
  };

  const handleCreateDoc = (payload: PayloadT) => {
    dispatch(createDoc(payload))
      .unwrap()
      .then((res) => {
        const path = `/docs/${res.type}/${res._id}`;

        handleFormReset();
        showSuccessAlert(t("Document created"));
        navigate(path);
      })
      .catch(() => {
        showErrorAlert(t("Failed to create document"));
      });
  };

  const handleUpdateDoc = (payload: EditPayloadT) => {
    dispatch(updateDoc(payload))
      .unwrap()
      .then(() => {
        showSuccessAlert(t("Document updated"));
        editCallback && editCallback();
      })
      .catch(() => {
        showErrorAlert(t("Failed to update document"));
      });
  };

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const payload = {
      pageNavTitle: data.title,
      type: docType,
      content: data.content,
    };

    const editPayload = {
      id: editID ?? "",
      data: payload,
    };

    if (type === "add") {
      return handleCreateDoc(payload);
    }

    return handleUpdateDoc(editPayload);
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <CardHeader
            component={() => (
              <Box
                sx={{
                  p: 5,
                  pb: 0,
                  display: "flex",
                  flexDirection: "column",
                  gap: 5,
                }}
              >
                <Typography variant="h6">
                  {type === "add" ? t("Add new document") : t("Edit document")}
                </Typography>

                <Box sx={{ width: "100%" }}>
                  <TextField
                    label="Document Title"
                    variant="outlined"
                    fullWidth
                    {...register("title")}
                    {...(type === "edit" && {
                      InputLabelProps: { shrink: true },
                    })}
                  />
                  {errors.title && errors.title.message && (
                    <FormHelperText sx={{ color: "error.main", mt: 2 }}>
                      {t(errors.title.message)}
                    </FormHelperText>
                  )}
                </Box>
              </Box>
            )}
          />

          <CardContent>
            <DocsEditor ref={docsEditorRef} />

            {errors.content && errors.content.message && (
              <FormHelperText sx={{ color: "error.main", mt: 1 }}>
                {t(errors.content.message)}
              </FormHelperText>
            )}

            {isSubmitting && <LinearProgress />}
          </CardContent>

          <CardActions>
            <Button
              variant="contained"
              sx={{ marginRight: "auto" }}
              type="submit"
              disabled={isSubmitting}
            >
              Save
            </Button>
          </CardActions>
        </Card>
      </form>
    </FormProvider>
  );
};

export default DocsForm;
