import React, { useEffect, useState, useCallback} from "react";
import { Box, Card, CardContent, Checkbox, Grid, Typography } from "@mui/material";

import { Admin } from "../../../../types/adminTypes";
import { fetchOneUserPerms, updatePermsissions } from "../../../../store/apps/permissions";
import { useDispatch } from "react-redux";
import { AppPermissionsT, AppResourceActionsT, AppResourceT } from "../../../../types/permission-types";
import { AppDispatch } from "../../../../store";
import { useAlertContext } from "../../../../hooks/useAlertContext";
import { useTranslation } from "react-i18next";

interface Props {
  t: any;
  admin: Admin;
}

const ViewInfo = (props: Props) => {
  const { admin } = props;
  const [permissions, setPermissions] = useState<AppPermissionsT | undefined>(undefined);

  const { t } = useTranslation();
  const { showErrorAlert, showSuccessAlert } = useAlertContext();
  const dispatch = useDispatch<AppDispatch>();

  const fetchCallback = () => {
    dispatch(fetchOneUserPerms(admin._id))
    .unwrap()
    .then((res) => setPermissions(res));
  }

  const fetchPermissions = useCallback(fetchCallback, [admin._id, dispatch]);

  useEffect(() => {
    fetchPermissions();
  }, [fetchPermissions]);

  const handlePermissionUpdate = (payload: {
    resource: AppResourceT;
    action: AppResourceActionsT;
    allowed: boolean;
  }) => {
    const data = {
      adminID: admin._id,
      ...payload
    };

    dispatch(updatePermsissions({ data }))
      .unwrap()
      .then(() => {
        fetchPermissions()
        showSuccessAlert(t("Permission updated"));
      })
      .catch((err) => {
        console.log(err)
        showErrorAlert(t("Failed to update Permission"));
      });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, resource: AppResourceT, action: AppResourceActionsT) => {
    const allowed = event.target.checked;

    const payload = {
      resource,
      action,
      allowed
    }

    handlePermissionUpdate(payload)
  };

  return (
    <Card>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
        }}
      >
        <Typography variant="h6" sx={{ textTransform: "capitalize" }}>
          {admin.displayName}'s Permisions
        </Typography>

        <Grid container spacing={8}>
          {permissions &&
            Object.keys(permissions).map((_resource) => {
              const resource = _resource as AppResourceT;
              const resourcePermissions = Object.keys(permissions[resource]);
              const permissionAction = (action: AppResourceActionsT) =>
                permissions[resource][action];

              return (
                <Grid
                  item
                  container
                  xs={12}
                  sm={6}
                  md={4}
                  spacing={1}
                  key={resource}
                >
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        textTransform: "capitalize",
                      }}
                    >
                      {resource}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    {[...resourcePermissions]
                      .sort((a, b) => (a < b ? -1 : 1))
                      .map((_permission) => {
                        const action = _permission as AppResourceActionsT;

                        return (
                          <Box
                            key={action}
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <Typography sx={{ flex: 0.65 }}>
                              Can {action}
                            </Typography>
                            <Checkbox checked={permissionAction(action)} onChange={(e) => {
                              handleChange(e, resource, action)
                            }} />
                          </Box>
                        );
                      })}
                  </Grid>
                </Grid>
              );
            })}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ViewInfo;
